import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

interface TextViewDraftJSProps {
  text: string
}

export function TextViewDraftJS({ text }: TextViewDraftJSProps) {
  const draftState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(text))
  )

  const textHtml = draftToHtml(convertToRaw(draftState.getCurrentContent()))

  return (
    <div
      className="text-view-html"
      dangerouslySetInnerHTML={{
        __html: textHtml,
      }}
    />
  )
}
